<template>
  <div class="row">
    <div class="col-12">
      <h5 style="color: #428a94; font-weight: bold">Update Kelas Kamar</h5>
      <h6 style="font-weight: 500" class="mt-5">Informasi Umum</h6>
      <div class="row">
        <div class="col-4 col-sm-12 my-4">
          <p>Jenis Perawatan<span style="color: #dc3545">*</span></p>
          <a-radio-group
            :options="jenisPerawatanOptions"
            v-model="jenisPerawatan"
          />
        </div>

        <div class="col-12 mt-4">
          <p class="m-0">
            Nama Kelas Kamar<span style="color: #dc3545">*</span>
          </p>
          <span class="m-0" style="font-size: 12px; color: #667085">
            Nama harus unik dari nama kelas kamar lain di master kelas kamar
          </span>
          <div class="row px-3 mt-2">
            <a-input
              placeholder="Nama Kelas Kamar"
              class="col-12 col-sm-6 col-md-4"
              v-model="namaKelasKamar"
            ></a-input>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mt-3" v-if="!isNamaKelasKamarEmpty">
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Nama Kelas Kamar masih kosong.
        </p>
      </div>
      <div class="d-flex align-items-center mt-3" v-if="!isNamaKelasKamarExist">
        <InfoCircleOutlined style="color: #dc3545" />
        <p class="m-0 ml-2" style="color: #dc3545">
          Sudah ada Kelas Kamar dengan nama ini.
        </p>
      </div>
      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">BHP Ruangan</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="bhpRuangan"
              placeholder="0"
              @input="filterInput('bhp')"
            ></a-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">Biaya Administrasi</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="biayaAdministrasi"
              placeholder="0"
              @input="filterInput('biaya')"
            ></a-input>
          </div>
        </div>
      </div>
      <a-divider></a-divider>
      <h6 style="font-weight: 500" class="mt-5">Tarif Kelas Kamar</h6>

      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">Umum/Mandiri</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifKelasKamarUmum"
              placeholder="0"
              @input="filterInput('tr-umum')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-0">
          <p class="">BPJS</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifKelasKamarBPJS"
              placeholder="0"
              @input="filterInput('tr-bpjs')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-3">
          <p class="">Asuransi Lainnya</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifKelasKamarAsuransiLain"
              placeholder="0"
              @input="filterInput('tr-lainnya')"
            ></a-input>
          </div>
        </div>
      </div>
      <a-divider></a-divider>
      <h6 style="font-weight: 500" class="mt-5">Tarif Perawatan Per Hari</h6>

      <div class="row">
        <div class="col-4 mt-4 pr-0">
          <p class="">Umum/Mandiri</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifPerawatanUmum"
              placeholder="0"
              @input="filterInput('tp-umum')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-0">
          <p class="">BPJS</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifPerawatanBPJS"
              placeholder="0"
              @input="filterInput('tp-bpjs')"
            ></a-input>
          </div>
        </div>
        <div class="col-4 mt-4 pr-3">
          <p class="">Asuransi Lainnya</p>
          <div class="d-flex">
            <p
              class="m-0 mr-3 d-flex align-items-center justify-content-center"
            >
              Rp
            </p>
            <a-input
              v-model="tarifPerawatanLainnya"
              placeholder="0"
              @input="filterInput('tp-lainnya')"
            ></a-input>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="mt-2 col-12 d-flex">
          <button
            class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
            style="width: 100px; border-radius: 26px"
            @click="handleUpdateModal"
          >
            <img
              src="/resources/images/edit.svg"
              alt="edit"
              class="icon-default"
            />
            <img
              src="/resources/images/edit-hover.svg"
              alt="edit-hover"
              class="icon-hover"
            />
            <span class="ml-2">Ubah</span>
          </button>
          <a-button
            v-if="status"
            size="large"
            @click.prevent="handleToggleStatus()"
            class="d-flex align-items-center justify-content-center btn btn-outline-danger delete-button ml-2"
            style="width: 150px; border-radius: 26px"
          >
            <a-icon type="close" />
            <span class="ml-2">Nonaktifkan</span>
          </a-button>
          <a-button
            size="large"
            v-else
            @click.prevent="handleToggleStatus()"
            class="d-flex align-items-center justify-content-center btn btn-outline-warning delete-button ml-2"
            style="width: 110px; border-radius: 26px"
          >
            <a-icon type="check" />
            <span class="ml-2">Aktifkan</span>
          </a-button>
        </div>
        <a-modal
          v-model="modalDeleteVisible"
          :title="`${
            this.status ? 'Nonaktifkan' : 'Aktifkan kembali'
          } kelas kamar?`"
          centered
          :footer="null"
        >
          <p>
            {{
              this.status
                ? `Setelah dinonaktifkan, kelas kamar ini tidak akan muncul pada opsi kelas kamar saat Anda menambahkan/mengubah data kamar. Anda dapat mengaktifkan kembali kelas kamar nanti.`
                : "Setelah diaktifkan kembali, kelas kamar ini akan muncul pada opsi kelas kamar saat Anda menambahkan/mengubah data kamar."
            }}
          </p>
          <div class="d-flex justify-content-end mt-4">
            <button
              class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
              style="width: 50%; border-radius: 26px"
              @click.prevent="handleCloseModal"
            >
              <span class="ml-2">Kembali</span>
            </button>
            <button
              @click.prevent="handleStatus"
              class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
              style="width: 50%; border-radius: 26px"
            >
              <span class="ml-2">Ya</span>
            </button>
          </div>
        </a-modal>
        <a-modal
          v-model="modalUpdateVisible"
          title="Ubah kelas kamar"
          centered
          :footer="null"
        >
          <p>
            Mohon cek kembali perubahan data kelas kamar sebelum menyimpan
            formulir.
          </p>
          <div class="d-flex justify-content-end mt-4">
            <button
              class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
              style="width: 50%; border-radius: 26px"
              @click.prevent="handleCloseModal"
            >
              <span class="ml-2">Kembali</span>
            </button>
            <button
              @click.prevent="submitData"
              class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
              style="width: 50%; border-radius: 26px"
            >
              <span class="ml-2">Ya</span>
            </button>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRoomClass,
  getRoomClassById,
  updateRoomClass,
  activateRoomClass,
  deactivateRoomClass,
} from "@/services/axios/api/rooms"
import { notification } from "ant-design-vue"
import { InfoCircleOutlined } from "@ant-design/icons-vue"
export default {
  components: {
    InfoCircleOutlined,
  },
  data: function () {
    return {
      jenisPerawatanOptions: [
        {
          label: "Rawat Non-Intensif",
          value: "Rawat Non-Intensif",
        },
        {
          label: "Rawat Intensif",
          value: "Rawat Intensif",
        },
      ],
      jenisPerawatan: "",
      namaKelasKamar: "",
      bhpRuangan: "",
      biayaAdministrasi: "",
      tarifKelasKamarUmum: "",
      tarifKelasKamarBPJS: "",
      tarifKelasKamarAsuransiLain: "",
      tarifPerawatanUmum: "",
      tarifPerawatanBPJS: "",
      tarifPerawatanLainnya: "",
      isNamaKelasKamarEmpty: true,
      isNamaKelasKamarExist: true,
      status: false,
      dataKamar: [],
      modalDeleteVisible: false,
      modalUpdateVisible: false,
      id: "",
    }
  },
  created() {
    const { id } = this.$route.params
    this.id = id
    this.getName()
    this.fetchData(id)
  },

  methods: {
    filterInput(type) {
      switch (type) {
        case "bhp":
          this.bhpRuangan = this.bhpRuangan.replace(/[^0-9]/g, "")

          break
        case "biaya":
          this.biayaAdministrasi = this.biayaAdministrasi.replace(/[^0-9]/g, "")

          break
        case "tr-umum":
          this.tarifKelasKamarUmum = this.tarifKelasKamarUmum.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tr-bpjs":
          this.tarifKelasKamarBPJS = this.tarifKelasKamarBPJS.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tr-lainnya":
          this.tarifKelasKamarAsuransiLain =
            this.tarifKelasKamarAsuransiLain.replace(/[^0-9]/g, "")

          break
        case "tp-umum":
          this.tarifPerawatanUmum = this.tarifPerawatanUmum.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tp-bpjs":
          this.tarifPerawatanBPJS = this.tarifPerawatanBPJS.replace(
            /[^0-9]/g,
            "",
          )

          break
        case "tp-lainnya":
          this.tarifPerawatanLainnya = this.tarifPerawatanLainnya.replace(
            /[^0-9]/g,
            "",
          )

          break

        default:
          break
      }
      // Menggunakan regex untuk hanya menerima angka
    },
    getName() {
      const name = localStorage.getItem("name")
      this.name = name
    },
    async fetchData(id) {
      const { data: allDataKamar } = await getRoomClass()
      this.dataKamar = allDataKamar.data
      const { data } = await getRoomClassById(id)
      console.log(data.data, "cekinidetailclassroom")
      const dataKamar = data.data
      this.jenisPerawatan = dataKamar.jenisPerawatan
      this.namaKelasKamar = dataKamar.name
      this.bhpRuangan = dataKamar.bhpRuangan
      this.biayaAdministrasi = dataKamar.biayaAdministrasi
      this.tarifKelasKamarUmum = dataKamar.tarifKelasKamarUmum
      this.tarifKelasKamarBPJS = dataKamar.tarifKelasKamarBPJS
      this.tarifKelasKamarAsuransiLain = dataKamar.tarifKelasKamarAsuransiLain
      this.tarifPerawatanUmum = dataKamar.tarifPerawatanUmum
      this.tarifPerawatanBPJS = dataKamar.tarifPerawatanBPJS
      this.tarifPerawatanLainnya = dataKamar.tarifPerawatanAsuransiLain
      this.status = dataKamar.status
    },

    async submitData() {
      let isQualified = true

      this.isNamaKelasKamarEmpty = true
      this.isNamaKelasKamarExist = true

      if (!this.namaKelasKamar) {
        this.isNamaKelasKamarEmpty = false
        isQualified = false
        this.modalUpdateVisible = false
      } else {
        const existingRoomClass = this.dataKamar.filter(
          (e) => e.name === this.namaKelasKamar && e.id !== this.id,
        )
        console.log(this.dataKamar)
        console.log(existingRoomClass)
        if (existingRoomClass.length) {
          this.isNamaKelasKamarExist = false
          isQualified = false
          this.modalUpdateVisible = false
        }
      }

      if (isQualified) {
        // console.log(
        //   this.jenisPerawatan,
        //   this.namaKelasKamar,
        //   this.bhpRuangan,
        //   this.biayaAdministrasi,
        //   this.tarifKelasKamarUmum,
        //   this.tarifKelasKamarBPJS,
        //   this.tarifKelasKamarAsuransiLain,
        //   this.tarifPerawatanUmum,
        //   this.tarifPerawatanBPJS,
        //   this.tarifPerawatanLainnya,
        // )
        const { data } = await updateRoomClass(
          this.jenisPerawatan,
          this.namaKelasKamar,
          this.bhpRuangan,
          this.biayaAdministrasi,
          this.tarifKelasKamarUmum,
          this.tarifKelasKamarBPJS,
          this.tarifKelasKamarAsuransiLain,
          this.tarifPerawatanUmum,
          this.tarifPerawatanBPJS,
          this.tarifPerawatanLainnya,
          this.id,
        )
        console.log(data)
        if (data.message.includes("Success")) {
          notification.success({
            message: data.message,
            description: "",
            duration: 5,
          })
          this.$router.push("/kelas-kamar")
        }
      } else {
        notification.warning({
          message: "Gagal Membuat Pemeriksaan",
          description:
            "Inputan masih belum memenuhi requirement yang diperlukan",
          duration: 5,
        })
      }
    },
    async handleToggleStatus() {
      this.selectedDeleteId = this.id
      this.selectedDeleteName = this.namaKelasKamar

      this.modalDeleteVisible = !this.modalDeleteVisible
    },
    handleCloseModal() {
      this.modalDeleteVisible = false
    },
    handleCloseModalUpdate() {
      this.modalUpdateVisible = false
    },
    handleUpdateModal() {
      this.modalUpdateVisible = true
    },
    async handleStatus() {
      try {
        if (!this.status) {
          await activateRoomClass(this.id)
        } else {
          await deactivateRoomClass(this.id)
        }

        this.fetchData(this.id)

        this.$notification.success({
          message: "Berhasil",
          description: `Data Master Pemeriksaan berhasil di${
            this.status ? "aktif" : "nonaktif"
          }kan`,
          duration: 5,
        })
        this.modalDeleteVisible = false
      } catch (error) {
        console.error("Error deleting data:", error)
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.delete-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.delete-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.delete-button:hover .icon-default {
  display: none;
}

.delete-button:hover .icon-hover {
  display: inline;
}

/* Default icon terlihat */
.edit-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.edit-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.edit-button:hover .icon-default {
  display: none;
}

.edit-button:hover .icon-hover {
  display: inline;
}
</style>
